import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NavLink, useNavigate } from "react-router-dom";
import { images } from "../../config";
import './Login.css';
import { styled } from '@mui/material/styles';
import { login } from './services';
import { setUserLogin } from './LoginSlice/LoginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const CustomFilledInput = styled(FilledInput)({
    borderRadius: '8px',
    '&:before': {
        borderBottom: 'none',
    },
    '&:after': {
        borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
    },
    root: {
        '& .MuiFilledInput-root': {
            borderBottom: 'none',
        },
    },
});

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });
    const [isChecked, setIsChecked] = useState(false);

    const { userDetail } = useSelector((state) => state.userLogin)

    console.log("userDetail", userDetail)

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleCheck = (e) => {
        setIsChecked(e.target.checked);
    };

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }


    const handleSubmit = async () => {
        if (!formData.username) {
            errorNotify("Username is required");
            return;
        }

        if (!formData.password) {
            errorNotify("Password is required");
            return;
        }
        const data = {
            username: formData.username,
            password: formData.password,
        };
        console.log("data", data);
        try {
            const response = await login(data);
            console.log("response", response);
            if (response.status === 200 || response.status === 201) {
                dispatch(setUserLogin({ userDetail: response.data.payload, keepMeSignIn: isChecked }));
                // dispatch(setUserLogin({ userDetail: "" }));
                const token = response.data.payload.token;
                console.log("Token123", token);
                if (isChecked) {
                    localStorage.setItem('authToken', token);
                } else {
                    sessionStorage.setItem('authToken', token);
                }
                notify(response.data.message);
                navigate("/dashboard");
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            if (error.response) {
                console.error("Error:", error.response.data.message);
                errorNotify(error.response.data.message);
            } else {
                console.error("Error sending form data:", error);
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        }
    };

    // useEffect(() => {
    //     const storedUserDetail = localStorage.getItem("userDetail");
    //     if (storedUserDetail) {
    //         dispatch(setUserLogin({ userDetail: JSON.parse(storedUserDetail) }));
    //     }
    // }, [dispatch]);

    // const handleSubmit = async () => {
    //     const data = {
    //         username: formData.username,
    //         password: formData.password,
    //     };
    //     try {
    //         const response = await login(data);
    //         if (response.status === 200 || response.status === 201) {
    //             localStorage.setItem("userDetail", JSON.stringify(response.data.payload));
    //             dispatch(setUserLogin({ userDetail: response.data.payload }));
    //             navigate("/dashboard");
    //         }
    //     } catch (error) {
    //         console.error("Error sending form data:", error);
    //     }
    // };


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" sx={{ padding: { lg: 0, xs: 0 } }} >
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                        <div className='sign-in-text-wrap'>
                            <div className='sign-in-text'>
                                <div>
                                    <img src={images.iSecureLogo} alt="image" className='i-secure-logo' />
                                </div>
                                <div className='sign-in-text'>
                                    <h2>Hello! let’s get started</h2>
                                    <h6>Sign in to continue</h6>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div>
                                            <TextField
                                                id="username"
                                                name="username"
                                                label="Enter Your Username"
                                                variant="filled"
                                                className='user-input'
                                                value={formData.username}
                                                onChange={handleChange}
                                                onKeyDown={handleKeyDown}
                                                InputProps={{
                                                    style: {
                                                        paddingTop: '7px',
                                                    },
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className='password-input1'>
                                            <FormControl variant="filled" className='password-input'>
                                                <InputLabel htmlFor="password" className='password-label'>Enter Your Password</InputLabel>
                                                <CustomFilledInput
                                                    id="password"
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                    InputProps={{
                                                        style: {
                                                            paddingTop: '5px',
                                                        },
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='sign-in-btn'>

                                    <button onClick={handleSubmit}>SIGN IN</button>

                                </div>
                                <div className='check-box-wrap'>
                                    <div>
                                        <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleCheck} />} label="Keep me signed in" />
                                    </div>
                                    <div>
                                        {/* <NavLink to="/forgotpassword"> */}
                                        {/* Forgot Password? */}
                                        {/* </NavLink> */}
                                    </div>
                                </div>
                                <div className='download-i-secure-text'>
                                    <div>
                                        <img src={images.androidLogo} alt="image" />
                                    </div>
                                    <div>
                                        <h4>Download i-Secure</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                        <img
                            src={images.loginImg}
                            alt="image"
                            className='main-img'
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Login;
