import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { updatePassword } from "./service";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './ChangePassword.css';

const CustomFilledInput = styled(FilledInput)({
    borderRadius: '8px',
    '&:before': {
        borderBottom: 'none',
    },
    '&:after': {
        borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
    },
    root: {
        '& .MuiFilledInput-root': {
            borderBottom: 'none',
        },
    },
});

const ChangePassword = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleUpdate = async () => {
        if (!oldPassword || !password || !confirmPassword) {
            errorNotify('All fields are required');
            return;
        }
        const data = {
            old_password: oldPassword,
            new_password: password,
            confirm_password: confirmPassword,
        }
        console.log("data", data);
        try {
            const response = await updatePassword(data);
            if (response.status === 200 || response.status === 201) {
                console.log("updatePassword", response);
                notify(response.data.message);
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
                setError('');
            }
        } catch (error) {
            console.error("Error sending form data", error);
            errorNotify(error.response.data.message);
        }
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleChangeOldPassword = (event) => {
        setOldPassword(event.target.value);
    }

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
        if (confirmPassword) {
            setError(value !== confirmPassword ? 'Password does not match' : '');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const value = event.target.value;
        setConfirmPassword(value);
        if (value) {
            setError(value !== password ? 'Password does not match' : '');
        } else {
            setError('');
        }
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-5 pb-xl-3 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Change Password</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className={`pt-2 pb-5 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                {/* ========== Grid 1 Start ========== */}
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <div className="change-passowrd-input-wrap">
                            <label className="change-password-label">Enter old password</label>
                            <TextField
                                id="filled-basic"
                                // label="Enter old password"
                                variant="filled"
                                className='profile-input'
                                value={oldPassword}
                                onChange={handleChangeOldPassword}
                                // InputProps={{
                                //     style: {
                                //         paddingTop: '5px',
                                //     },
                                // }}
                            />
                        </div>
                        <div className="change-passowrd-input-wrap pt-3">
                            <label className="change-password-label">Enter New Password</label>
                            <FormControl variant="filled" className='distributer-password-input'>
                                {/* <InputLabel htmlFor="filled-adornment-password" className='distributer-password-label'>
                                    Enter Your Password
                                </InputLabel> */}
                                <CustomFilledInput
                                    id="filled-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    // InputProps={{
                                    //     style: {
                                    //         paddingTop: '5px',
                                    //     },
                                    // }}
                                />
                            </FormControl>
                        </div>
                        <div className="change-passowrd-input-wrap pt-3">
                            <label className="change-password-label">Confirm password</label>
                            <FormControl variant="filled" className='distributer-password-input'>
                                {/* <InputLabel htmlFor="filled-adornment-password" className='distributer-password-label'>
                                    Enter Confirm Password
                                </InputLabel> */}
                                <CustomFilledInput
                                    id="filled-adornment-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    // InputProps={{
                                    //     style: {
                                    //         paddingTop: '5px',
                                    //     },
                                    // }}
                                />
                            </FormControl>
                            {error && <p style={{ color: 'red', letterSpacing: '1px', fontSize: '16px' }}>{error}</p>}
                        </div>
                        <button className="update-password-btn" onClick={handleUpdate}>
                            Update password
                        </button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ChangePassword;