import React, { useState, useEffect } from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Paper,
} from '@mui/material';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import './DistributersList.css';
import { getDistributer, getCountry, getState, getCity, getDistributerExcel, distributerStatus } from "./service";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const columns = [
    { id: 'sr', label: 'Sr' },
    { id: 'name', label: 'Name' },
    { id: 'company_name', label: 'Company Name' },
    { id: 'mobile_number', label: 'Mobile' },
    { id: 'parent_name', label: 'Parent Name' },
    { id: 'owner_name', label: 'Owner Name' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' },
];

const DistributersList = () => {

    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [city, setCity] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [status, setStatus] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sr');
    const [action, setAction] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [distributerData, setDistributerData] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userName, setUserName] = useState({ name: "" });
    const [count, setCount] = useState(0);
    const [dropdownValues, setDropdownValues] = useState({});
    const [sortedDataList, setSortedDataList] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const navigate = useNavigate();

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleClick = () => {
        navigate('/addDistributer')
    }

    const handleClickUserView = (rowId) => {
        navigate(`/DistributerView/${rowId}/false`);
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    }

    // const handleChangeAction = (event, rowId) => {
    //     setDropdownValues(prevState => ({
    //         ...prevState,
    //         [rowId]: event.target.value
    //     }));
    // };

    const handleChangeAction = async (event, rowId) => {
        const selectedAction = event.target.value;
        setDropdownValues(prevValues => ({
            ...prevValues,
            [rowId]: selectedAction,
        }));

        if (selectedAction === "view") {
            navigate(`/DistributerView/${rowId}/false`);
        }
        if (selectedAction === "edit") {
            navigate(`/DistributerView/${rowId}/true`);
        }
        if (selectedAction === "transfer") {
            navigate(`/transferPoint/${rowId}`);
        }

        // if (selectedAction === "block") {
        //     const requestData = {
        //         status: "block",
        //         distributor_id: rowId
        //     };
        //     console.log("requestData", requestData);
        //     try {
        //         const response = await distributerStatus(requestData);
        //         console.log('Distributor blocked successfully', response.data);

        //     } catch (error) {
        //         console.error('Error blocking distributor', error);

        //     }
        // }

        if (selectedAction === "Blocked" || selectedAction === "Unblocked") {
            const requestData = {
                status: selectedAction,
                distributor_id: rowId
            };

            console.log("requestData", requestData);

            try {
                const response = await distributerStatus(requestData);
                handleSubmit();
                console.log(`Distributor ${selectedAction}ed successfully`, response.data);
                // setDropdownValues(prevValues => ({
                //     ...prevValues,
                //     [rowId]: newStatus
                // }));

            } catch (error) {
                console.error(`Error ${selectedAction}ing distributor`, error);
            }
        }

    };

    // const handleSort = (property, order) => {
    //     setOrder(order);
    //     setOrderBy(property);
    // };

    const handleSort = (property, orderType) => {
        // const isAsc = orderBy === property && order === 'asc';
        setOrder(orderType);
        setOrderBy(property);
        console.log("property", property);
        console.log("orderType", orderType);
        console.log("order", order);
        console.log("orderBy", orderBy);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeName = (e) => {
        setUserName({
            ...userName,
            [e.target.name]: e.target.value,
        });
    }

    const handleRefresh = () => {
        setUserName({ ...userName, name: '' });
        setSelectedCountry("");
        setSelectedState("");
        setSelectedCity("");
        setStatus("");
        setRefresh(refresh === 0 ? 1 : 0);
    };

    useEffect(() => {
        handleSubmit();
    }, [refresh]);


    const handleExportExcel = async () => {
        const data = {
            name: userName,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
            status: status
        }
        console.log("distributer", data);
        try {
            const response = await getDistributerExcel(userName, selectedCountry, selectedState, selectedCity, status);
            console.log("response", response);
        }
        catch (error) {
            console.error("Error sending form data:", error);
        }
    }

    useEffect(() => {
        fetchCountry();
        handleSubmit();
    }, []);


    const sortData = () => {
        setSortedDataList([...distributerData].sort((a, b) => {
            let valueA = a[orderBy];
            let valueB = b[orderBy];
            // Handle undefined values
            // Handle undefined or null values
            if (valueA === undefined || valueA === null) valueA = '';
            if (valueB === undefined || valueB === null) valueB = '';


            // Check if both values are numeric
            if (!isNaN(valueA) && !isNaN(valueB)) {
                valueA = parseFloat(valueA);
                valueB = parseFloat(valueB);
            } else {
                // Ensure strings are compared case-insensitively
                valueA = valueA.toString().toLowerCase();
                valueB = valueB.toString().toLowerCase();
            }

            if (order === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        }));
    };

    useEffect(() => {
        handleSubmit();
    }, [page, rowsPerPage]);


    useEffect(() => {
        sortData();
    }, [order, orderBy, distributerData]);


    //Get Country list
    const fetchCountry = async () => {
        try {
            const response = await getCountry()
            if (response.status === 200 || response.status === 201) {
                setCountry(response.data.payload)
                console.log("setCountry", response);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    // Get state list
    const handleChangeCountry = async (event) => {
        const selectedCountryValue = event.target.value;
        setSelectedCountry(selectedCountryValue);
        console.log("SelectedCountry", selectedCountryValue);
        const data = {
            name: selectedCountryValue,
        };
        console.log("data123country", data);
        try {
            const response = await getState(data);
            if (response.status === 200 || response.status === 201) {

                // const states = response.data.states;
                // if (Array.isArray(states)) {
                //     setState(states);
                // } else {
                //     console.error("Expected an array of states, but received:", states);
                //     setState([]);
                // }

                console.log("stateResponse", response.data.states);
                setState(response.data.states);

            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };


    // Get city list
    const handleChangeState = async (event) => {
        const selectedStateValue = event.target.value;
        setSelectedState(selectedStateValue);
        console.log("SelectedState", selectedStateValue);
        const data = {
            state_name: selectedStateValue,
        }
        console.log("dataState", data);
        try {
            const response = await getCity(data);
            if (response.status === 200 || response.status === 201) {
                console.log("cityResponse", response);
                setCity(response.data.cities)
            }
        } catch (error) {
            console.log("Error sending form data:", error);
        }
    };


    const handleChangeCity = (event) => {
        const selectedCityValue = event.target.value;
        setSelectedCity(selectedCityValue);
    }

    const handleSubmit = async () => {
        const data = {
            name: userName,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
            status: status
        }
        console.log("distributer", data);
        try {
            const pageSize = rowsPerPage || 10;
            console.log("pageSize", pageSize)
            const response = await getDistributer(data, userName, selectedCountry, selectedState, selectedCity, status, page, pageSize);
            if (response.status === 200 || response.status === 201) {
                const results = response.data.results;
                console.log("distributer123444", response.data.results);
                if (results.length === 0) {
                    errorNotify("Distributor not found");
                    setCount(0);
                    setDistributerData([]);
                } else {
                    console.log("count", response.data.count)
                    setCount(response.data.count);
                    setDistributerData(response.data.results);
                    notify(response.data.message);
                }
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }
  

    useEffect(() => {
        const handleSidebarToggle = () => {
            const storedValue = localStorage.getItem('sidebarOpen') === 'true';
            console.log('Sidebar toggled, new value:', storedValue);
            setSidebarOpen(storedValue);
        };

        // Initial load
        handleSidebarToggle();

        // Listen for the custom event
        window.addEventListener('sidebarToggle', handleSidebarToggle);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('sidebarToggle', handleSidebarToggle);
        };
    }, []);
    


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className={`pt-4 pb-0 pt-sm-4 pb-sm-0 pt-md-4 pb-mb-0 pt-lg-4 pb-lg-0 pt-xl-5 pb-xl-3 ${sidebarOpen ? 'sidebar-open-text' : 'sidebar-close-text'}`}>
                <div className="header-text-wrap">
                    <div className="total-count">
                        <h2 className="header-text">Distributor List</h2>
                        <p>Total records - {count}</p>
                    </div>
                    <div>
                        <button onClick={handleClick} className="add-distributor-btn">
                            Add Distributor
                        </button>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-4">
                <div className="search-data-bg">
                    <div className="distributer-list-form">
                        <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="distributer-input-wrap">
                                    <div>
                                        <label className="distributer-label">Search By Name or Company Name</label>
                                    </div>
                                    <div className="input-wrap">
                                        <input name="name" value={userName.name} onChange={handleChangeName} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">Country</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Search for country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={selectedCountry}
                                            onChange={handleChangeCountry}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {country.map((country) => (
                                                <MenuItem key={country.id} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} className="pt-4">
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">State</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Search for state</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={selectedState}
                                            onChange={handleChangeState}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {state.map((state, index) => (
                                                <MenuItem key={index} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} className="pt-4">
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">City</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Search for city</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={selectedCity}
                                            onChange={handleChangeCity}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {Array.isArray(city) && city.length > 0 ? (
                                                city.map((city, index) => (
                                                    <MenuItem key={index} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>
                                                    <em>No cities available</em>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} className="pt-4">
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">Status</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={status}
                                            onChange={handleChangeStatus}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Unblocked">Unblocked</MenuItem>
                                            <MenuItem value="Blocked">Blocked</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="userlist-btns-wrap">
                            <div>
                                <MdRefresh className="refresh-icon" onClick={handleRefresh} />
                            </div>
                            <div>
                                <button className="cancel-btn" onClick={handleExportExcel}>Export</button>
                            </div>
                            <div>
                                <button className="submit-btn" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Container maxWidth="xxl" className="pt-4 pb-5">
                <TableContainer component={Paper}>
                    <Table className="distributer-list-table-wrap">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <>
                                        <TableCell key={column.id} className="distributer-list-table-header-bg"
                                            sx={{
                                                border: 'none',
                                                '&:last-child': { border: 0 }
                                            }}
                                        >
                                            <div className="header-text-wrapper">
                                                <div className="header-text">
                                                    {column.label}
                                                </div>

                                                <div>
                                                    <div>
                                                        <IoIosArrowUp onClick={() => handleSort(column.id, 'desc')} className="up-icon" />
                                                        {/* <IoIosArrowUp onClick={() => handleSort(column.id)} className="up-icon" /> */}
                                                    </div>

                                                    <div>
                                                        <IoIosArrowDown
                                                            onClick={() => handleSort(column.id, 'asc')} className="down-icon" />
                                                        {/* <IoIosArrowDown onClick={() => handleSort(column.id)} className="down-icon" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="distributer-list-table">
                            {sortedDataList.map((row, index) => (
                                <TableRow key={row.id || index}>
                                    <TableCell sx={{ padding: 0 }}>{page * rowsPerPage + index + 1}</TableCell>
                                    <TableCell sx={{ padding: 0 }}>{row.name}</TableCell>
                                    <TableCell onClick={() => handleClickUserView(row.id)}>{row.company_name}</TableCell>
                                    <TableCell>{row.mobile_number}</TableCell>
                                    <TableCell>{row.created_by}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        <button className={row.status === "Unblocked" ? "status-unblock" : row.status === "Blocked" ? "status-block" : row.status === null ? "status-null" : ""}>
                                            {row.status === "Unblocked" ? row.status : (row.status === "Blocked" ? row.status : "-")}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{ m: 1 }} className="distributer-list-action-dropdown">
                                            <Select
                                                value={dropdownValues[row.id] || "action"}
                                                onChange={(e) => handleChangeAction(e, row.id)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className="distributer-list-action-dropdown"
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    fontFamily: 'Satoshi-Regular',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.5px'
                                                }}
                                            >
                                                <MenuItem value="action">
                                                    Action
                                                </MenuItem>
                                                <MenuItem value="view">View</MenuItem>
                                                <MenuItem value="edit">Edit</MenuItem>
                                                <MenuItem value="transfer">Transfer</MenuItem>
                                                <MenuItem value="Blocked">Block</MenuItem>
                                                <MenuItem value="Unblocked">Unblock</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    // onPageChange={(e, newPage) => setPage(newPage)}
                    // rowsPerPage={rowsPerPage}
                    // onRowsPerPageChange={(e) => {
                    //     setRowsPerPage(parseInt(e.target.value, 10));
                    //     setPage(0);
                    // }}
                    />
                </TableContainer>
            </Container>
        </>
    )
}

export default DistributersList;

