
import React, { useRef, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import { useDispatch, useSelector } from 'react-redux';
import { clearStore } from '../../redux/ClearStore/clearStore';
import { NavLink, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { images } from "../../config"
import './Sidebar.css';

const drawerWidth = 264;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Sidebar({ children }) {
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const sidebarRef = useRef(null);

    const handleDrawerOpen = () => {
        if (open) {
            setOpenSubMenu(false);
        }
        setOpen(!open);
    };

    const { userDetail } = useSelector((state) => state.userLogin);
    console.log("sidebar123", userDetail)


    // useEffect(() => {
    //     // console.log('Setting sidebarOpen in localStorage:', open);
    //     localStorage.setItem('sidebarOpen', open);
    // }, [open]);


    //working
    useEffect(() => {
        console.log('Setting sidebarOpen in localStorage:', open);
        localStorage.setItem('sidebarOpen', open);

        // Dispatch custom event for sidebar change
        const event = new Event('sidebarToggle');
        window.dispatchEvent(event);
    }, [open]);


    const handleSidebarItemClick = (subMenuId) => {
        if (!open) {
            setOpen(true);
        }
        setOpenSubMenu((prevSubMenu) => (prevSubMenu === subMenuId ? null : subMenuId));
    };

    // const handleSidebarItemClick = (subMenuId) => {
    //     if (openSubMenu === subMenuId) {
    //         setOpenSubMenu(null);
    //     } else {
    //         setOpenSubMenu(subMenuId);
    //     }
    // };

    const handleSidebarItemClickLogout = () => {
        setShowPopup(true);
    }

    const dispatch = useDispatch()

    const navigate = useNavigate();

    const handleClosePopup = () => {
        setShowPopup(false);
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');

        dispatch(clearStore());
        navigate('/login');
    };

    const handleConfirmLogout = () => {
        setShowPopup(false);
    };


    return (
        <Box sx={{ display: 'flex' }}>
            {/* ========== Navbar Start ========== */}
            <AppBar position="fixed" open={open} className='bg-navbar'>
                <Container maxWidth="xxl" sx={{ padding: { lg: 0, xs: 0 } }} >
                    <div className='navbar-wrap'>
                        <div className='menu-wrap'>
                            <div>
                                <img
                                    src={open ? images.navbarLogo : images.navbarLogo1}
                                    alt="image"
                                    className={open ? 'navbar-logo' : 'navbar-logo1'}
                                />
                            </div>
                            <div>
                                <FiMenu className="menu-icon"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        ...(open && { display: 'none' }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className='profile-wrap'>
                            <div>
                                <h6>MY IP : {userDetail.ip_address}</h6>
                            </div>
                            <div className='profile-img-wrap'>
                                <div>
                                    <NavLink to="/profile">
                                        <img src={images.userImage} alt="image" className='' />
                                    </NavLink>
                                </div>
                                <div>
                                    <h6>
                                        {userDetail.username}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </AppBar>
            {/* ========== Navbar End ========== */}

            {/* ========== Sidebar Start ========== */}
            <Drawer variant="permanent" open={open}>
                <div class="wrapper">
                    <aside id="sidebar" ref={sidebarRef}>
                        <div class="">
                            <ul class="sidebar-nav">
                                <li class="sidebar-item" onClick={handleSidebarItemClick}>
                                    <NavLink to="/dashboard" className="sidebar-link">
                                        <img src={images.dashboardIcon} alt="image" className='sidebar-icon me-4' />
                                        Dashboard
                                    </NavLink>
                                </li>
                                {userDetail.user_role !== 'Distributor' && (
                                    <li className="sidebar-item" onClick={() => handleSidebarItemClick('distributers')}>
                                        <a href="#" className="sidebar-link collapsed" data-bs-toggle="collapse" data-bs-target="#distributers"
                                            aria-expanded={openSubMenu === 'distributers'} aria-controls="distributers">
                                            <img src={images.distributerIcon} alt="image" className='sidebar-icon me-4' />
                                            Distributor
                                        </a>
                                        <ul id="distributers" className={`sidebar-dropdown collapse menu-dropdown ${openSubMenu === 'distributers' ? 'show' : ''}`} data-bs-parent="#sidebar">
                                            <li className="sidebar-item">
                                                <NavLink to="/addDistributer" className="sidebar-link">
                                                    Add
                                                </NavLink>
                                            </li>
                                            <li className="sidebar-item">
                                                <NavLink to="/distributersList" className="sidebar-link">
                                                    List
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                )}

                                <li class="sidebar-item" onClick={() => handleSidebarItemClick('retailer')}>
                                    <a href="#" class="sidebar-link collapsed" data-bs-toggle="collapse" data-bs-target="#retailer"
                                        aria-expanded={openSubMenu === 'retailer'} aria-controls="retailer">
                                        <img src={images.retailerIcon} alt="image" className='sidebar-icon me-4' />
                                        Retailer
                                    </a>
                                    <ul id="retailer" className={`sidebar-dropdown collapse menu-dropdown ${openSubMenu === 'retailer' ? 'show' : ''}`} data-bs-parent="#sidebar">
                                        <li class="sidebar-item">
                                            <NavLink to="/addRetailer" className="sidebar-link">Add</NavLink>
                                        </li>
                                        <li class="sidebar-item">
                                            <NavLink to="/retailersList" className="sidebar-link">List</NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li class="sidebar-item" onClick={handleSidebarItemClick}>
                                    <NavLink to="/myTransaction" className="sidebar-link">
                                        <img src={images.transactionIcon} alt="image" className='sidebar-icon me-4' />
                                        My Transaction
                                    </NavLink>
                                </li>
                                <li class="sidebar-item" onClick={handleSidebarItemClick}>
                                    <NavLink to="/userList" className="sidebar-link">
                                        <img src={images.userListIcon} alt="image" className='sidebar-icon me-4' />
                                        User List
                                    </NavLink>
                                </li>
                                <li class="sidebar-item" onClick={handleSidebarItemClick}>
                                    <NavLink to="/financeLocker" className="sidebar-link">
                                        <img src={images.financeLocakerIcon} alt="image" className='sidebar-icon me-4' />
                                        Finance Locker
                                    </NavLink>
                                </li>
                                <li class="sidebar-item" onClick={handleSidebarItemClick}>
                                    <NavLink to="/transferPoint" className="sidebar-link">
                                        <img src={images.iSecureicon} alt="image" className='sidebar-icon me-4' />
                                        i-Secure
                                    </NavLink>
                                </li>
                                <li class="sidebar-item" onClick={() => handleSidebarItemClick('advertising')}>
                                    <a href="#" class="sidebar-link collapsed" data-bs-toggle="collapse" data-bs-target="#advertising"
                                        aria-expanded={openSubMenu === 'advertising'} aria-controls="advertising">
                                        <img src={images.retailerIcon} alt="image" className='sidebar-icon me-4' />
                                        Advertising
                                    </a>
                                    <ul id="advertising" className={`sidebar-dropdown collapse menu-dropdown ${openSubMenu === 'advertising' ? 'show' : ''}`} data-bs-parent="#sidebar">
                                        <li class="sidebar-item">
                                            <NavLink to="/uploadBanner" className="sidebar-link">Upload</NavLink>
                                        </li>
                                        {userDetail.user_role !== 'Superadmin' && (
                                            <li class="sidebar-item">
                                                <NavLink to="/sendNotification" className="sidebar-link">Send Notification</NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                                <li class="sidebar-item" onClick={handleSidebarItemClick}>
                                    <NavLink to="/changePassword" className="sidebar-link">
                                        <img src={images.changepasswordicon} alt="image" className='sidebar-icon me-4' />
                                        Change Password
                                    </NavLink>
                                </li>
                                <li class="sidebar-item" onClick={handleSidebarItemClickLogout}>
                                    <a href="#" class="sidebar-link">
                                        <img src={images.logoutIcon} alt="image" className='sidebar-icon me-4' />
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </Drawer>
            {/* ========== Sidebar End ========== */}
            {/* ========== Logout confirmation Modal Start ========== */}
            {showPopup && (
                <div className="logout-confimartion popup-overlay">
                    <div className="logout-confimartion popup">
                        <p>Are you sure you want to logout?</p>
                        <div className='logout-confirmation-btns'>
                            <button onClick={handleConfirmLogout} className='cancel-btn'>Cancel</button>
                            <button onClick={handleClosePopup} className='logout-btn'>Logout</button>
                        </div>
                    </div>
                </div>
            )}
            {/* ========== Logout confirmation Modal End ========== */}
            <main>{children}</main>
        </Box>
    );
}
