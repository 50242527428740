import { request } from "../../api/API"
import { multipartRequest, downloadFile } from "../../api/API"

// export const getReatiler = (data, userName) => {
//     return request.get(`/custom_panel/getRetailerList/?search=${userName.name}`, data);
// }

export const getReatiler = (data, userName, createdByName, selectedRole, selectedCountry, selectedState, selectedCity, status, fromDate, toDate, page, pageSize) => {
    return request.get(`/custom_panel/getRetailerList/?search=${userName.name}&created_by_name=${createdByName}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&status=${status}&from_date=${fromDate}&to_date=${toDate}&page=${page + 1}&page_size=${pageSize}`, data);
}

export const getRetailerExcelExcel = (userName, createdByName, selectedRole, selectedCountry, selectedState, selectedCity, status, fromDate, toDate,) => {
    return downloadFile(`/custom_panel/getRetailerListExcel/?search=${userName.name}&created_by=${createdByName}&parent_role=${selectedRole}&country=${selectedCountry}&state=${selectedState}&city=${selectedCity}&status=${status}&from_date=${fromDate}&to_date=${toDate}`, "retailer_data.xlsx",);
}

export const getCountry = () => {
    return request.get('/custom_panel/getCountry/');
}

export const getState = (data) => {
    return request.post('/custom_panel/getState/', data);
}

export const getCity = (data) => {
    return request.post('/custom_panel/getCity/', data);
}

export const getParentRole = () => {
    // return request.get('/custom_panel/showUserRole/');
    return request.get('/custom_panel/showRetailerUserRole/');
}

export const getParentUser = (data) => {
    return multipartRequest.post('/custom_panel/userRoleList/', data);
}


// Retailer view and update

export const getRetailerView = (id) => {
    return request.get(`/custom_panel/getRetailerList/${id}`);
}

export const updateRetailer = (id, data) => {
    return multipartRequest.patch(`/custom_panel/updateRetailerDetails/${id}/`, data);
}


export const retailerStatus = (data) => {
    return request.post('/custom_panel/updateRetailerStatus/', data);
}