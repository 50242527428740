import React, { useState, useEffect } from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { images } from "../../config";
import { getFinance } from "./service";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { getUserData, lockDevice, unInstallPolicy, getSearchIMEI } from "./service";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './FinanceLocker.css';

const FinanceLocker = () => {

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const params = useParams();
    console.log(params, "id")

    useEffect(() => {
        fetchUserData();
    }, [params.id]);



    const [finance, setFinance] = useState([]);
    const [imeiNumber, setImeiNumber] = useState('');
    const [userData, setUserData] = useState([]);
    const [financeId, setFinanceId] = useState(null);
    const [suggestedIMEI, setSuggestedIMEI] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedCode, setSelectedCode] = useState(''); 
    const [inputValue, setInputValue] = useState('');
    const [selectedIMEI, setSelectedIMEI] = useState(''); 

    // const handleChangeIMEINumber = (e) => {
    //     setImeiNumber(e.target.value);
    // }

    // const handleChangeIMEINumber = async (event) => {
    //     const value = event.target.value;
    //     setImeiNumber(value);
    //     try {
    //         const response = await getSearchIMEI(value);
    //         if (response.status === 200 || response.status === 201) {
    //             // const imeiSuggestions = response.data.payload.map(item => item.imei_1);
    //             const imeiSuggestions = response.data.payload.map(item => ({
    //                 imei_1: item.imei_1,
    //                 code_no: item.code_no
    //             }));
    //             setSuggestedIMEI(imeiSuggestions);
    //             // console.log("Search IMEI", response.data);
    //             console.log("imeiSuggestions", imeiSuggestions);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching IMEI data", error);
    //         errorNotify(error.response?.data?.message);
    //     }
    // };

    const handleChangeInput = async (event) => {
        const value = event.target.value;
        setInputValue(value);
        setSelectedType('');
        console.log(`Typed Input Value: ${value}`);
        try {
            const response = await getSearchIMEI(value);
            if (response.status === 200 || response.status === 201) {
                const imeiSuggestions = response.data.payload.map(item => ({
                    imei_1: item.imei_1,
                    code_no: item.code_no
                }));
                setSuggestedIMEI(imeiSuggestions);
            }
        } catch (error) {
            console.error("Error fetching IMEI data", error);
        }
    };

    useEffect(() => {
        if (selectedIMEI) {
            console.log("Selected IMEI: ", selectedIMEI);
        }
        if (selectedCode) {
            console.log("Selected Code: ", selectedCode);
        }
    }, [selectedIMEI, selectedCode]);

    // const handleSuggestionClick = (imei) => {
    //     setImeiNumber(imei);
    //     setSelectedType('IMEI');
    //     console.log("Selected suggestion Number", imei);
    //     // setSuggestedIMEI([]);
    // };

    const handleSuggestionClick = (imei) => {
        setInputValue(imei); 
        setSelectedType('IMEI'); 
        setSelectedIMEI(imei);  
        console.log("Selected IMEI Number", imei);
    };

    const handleCodeClick = (code) => {
        setInputValue(code);
        setSelectedType('Code');
        setSelectedCode(code);
        console.log("Selected Code Number", code);
    };

    const handleRefresh = () => {
        setImeiNumber("");
        setInputValue("");
    };

    // const handleSubmit = async () => {
    //     const data = {
    //         imei_1: imeiNumber,   
    //     }
    //     console.log("imeiData", data);
    //     try {
    //         const response = await getFinance(data);
    //         if (response.status === 200 || response.status === 201) {
    //             console.log('imeiDataResponse', response.data.payload);
    //             const financeData = response.data.payload;
    //             setFinance(financeData);
    //             setFinanceId(financeData.id);
    //             localStorage.setItem('financeId', financeData.id);
    //             notify(response.data.message);
    //             console.log("financeDatafinanceData", financeData)
    //         }
    //     } catch (error) {
    //         console.error("Error sending form data:", error);
    //     }
    // }

    const handleSubmit = async () => {
        let data = {};
        if (selectedType === 'IMEI') {
            data = { imei_1: inputValue };
        } else if (selectedType === 'Code') {
            data = { code_no: inputValue };
        } else {
            errorNotify('Please select the IMEI number or code number');
            return;
        }

        console.log("Form Data", data);

        try {
            const response = await getFinance(data);
            if (response.status === 200 || response.status === 201) {
                console.log('imeiDataResponse', response.data.payload);
                const financeData = response.data.payload;
                setFinance(financeData);
                setFinanceId(financeData.id);
                localStorage.setItem('financeId', financeData.id);
                notify(response.data.message);
                setSuggestedIMEI([]);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await getUserData(params.id)
            if (response.status === 200 || response.status === 201) {
                console.log("userresponse444", response.data);
                setUserData(response.data);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleClickLockDevice = async () => {
        // const storedId = localStorage.getItem('financeId');
        // if (!storedId) {
        //     console.error("No financeId found , Please complete the IMEI number submission first");
        //     notify("Please complete the IMEI number submission first");
        //     return;
        // }
        if (!params.id && !imeiNumber.trim()) {
            console.error("IMEI number is empty. Please enter an IMEI number before proceeding.");
            errorNotify("Please complete the IMEI number submission first123");
            return;
        }
        if (!params.id && !financeId) {
            console.error("Please complete the IMEI number submission first");
            errorNotify("Please complete the IMEI number submission first");
            return;
        }
        const installType = (finance?.device_controls?.device_status === false || userData?.device_controls?.device_status === false)
            ? "KIOSK"
            : "PREINSTALLED";
        const idToUse = financeId || params.id;
        const data = {
            customer_id: idToUse,
            policy_name: idToUse,
            package_name: "com.example.i_secure_user_app",
            install_type: installType
        }
        console.log("datapayload", data)
        try {
            const response = await lockDevice(idToUse, data)
            if (response.status === 200 || response.status === 201) {
                console.log("responsedata", response);
                notify(response.data.message);


                console.log("financefinance", finance);
                console.log("userDatauserData", userData);


                // Update finance state or userData state to trigger re-render
                if (finance?.device_controls?.device_status !== undefined) {
                    setFinance({
                        ...finance,
                        device_controls: {
                            ...finance.device_controls,
                            device_status: !finance.device_controls.device_status, // Toggle status
                        }
                    });
                } else if (userData?.device_controls?.device_status !== undefined) {
                    setUserData({
                        ...userData,
                        device_controls: {
                            ...userData.device_controls,
                            device_status: !userData.device_controls.device_status, // Toggle status
                        }
                    });
                }
            }
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }


    const handleUnInstallPolicy = async () => {
        const idToUse = financeId || params.id;
        const data = {
            customer_id: idToUse,
            policy_name: idToUse,
        }
        try {
            const response = await unInstallPolicy(data);
            console.log("responsePolicy", response);

        } catch (error) {
            console.error("Error fetching data:", error);
            errorNotify(error.response.data.error);
        }
    }


    useEffect(() => {
        if (!params.id) {
            localStorage.removeItem('financeId');
            setFinanceId(null);
        }
    }, [params.id]);


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className="pt-4">
                <div className="header-text-wrap">
                    <div>
                        <h2 className="header-text">Finance Locker</h2>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-4">
                <div className="search-data-bg">
                    <div className="finance-list-form">
                        <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="finance-locker-box">
                                    <div>
                                        <label className="finance-label">Code or IMEI number</label>
                                    </div>
                                    <div className="finance-locker-input-wrap">
                                        <div className="imei-suggestion-box-wrap">
                                            <div className="input-wrap">
                                                {/* <input value={imeiNumber} onChange={handleChangeInput} /> */}
                                                <input
                                                    value={inputValue}
                                                    onChange={handleChangeInput}
                                                />
                                            </div>
                                            {/* working imei suggestions */}
                                            {/* {suggestedIMEI.length > 0 && (
                                                <div className="imei-suggestions-box">
                                                    {suggestedIMEI.map((imei, index) => (
                                                        imei !== null && (
                                                            <div key={index} className="imei-suggestion" onClick={() => handleSuggestionClick(imei)}>
                                                                {imei}
                                                            </div>
                                                        )
                                                    ))}
                                                </div>
                                            )} */}

                                            {suggestedIMEI.length > 0 && (
                                                <div className="imei-suggestions-box">
                                                    {/* IMEI List */}
                                                    <div className="imei-list">
                                                        {/* <h4>IMEI Numbers</h4> */}
                                                        {suggestedIMEI.map((item, index) => (
                                                            item.imei_1 && (
                                                                <div key={index} className="imei-suggestion" onClick={() => handleSuggestionClick(item.imei_1)}>
                                                                    {item.imei_1}
                                                                </div>
                                                            )
                                                        ))}
                                                    </div>

                                                    {/* Code List */}
                                                    <div className="code-list">
                                                        {/* <h4>Code Numbers</h4> */}
                                                        {suggestedIMEI.map((item, index) => (
                                                            <div key={index} className="code-suggestion" onClick={() => handleCodeClick(item.code_no)}>
                                                                {item.code_no}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div className="finance-locker-btns-wrap">
                                            <div>
                                                <MdRefresh className="refresh-icon" onClick={handleRefresh} />
                                            </div>
                                            <div>
                                                <button className="submit-btn" onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>

            <Container maxWidth="xxl" className="pt-4 pb-5">
                <div className="new-phone-text">
                    <h2 className="">New Phone</h2>
                </div>
                <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <div className="device-details-container">
                            <div className="device-image">
                                <h2>Device Details (smart Phone)</h2>
                                <img src={images.phoneImage} alt="image" className='' />
                            </div>
                            <div className="device-info pt-5">
                                <div className="info-row action">
                                    <div className="info-title">
                                        Action :
                                    </div>
                                    <div className="device-action">
                                        <button className={`action-btn ${finance?.device_controls?.device_status !== undefined
                                            ? (finance.device_controls.device_status ? 'lock' : 'unlock')
                                            : (userData?.device_controls?.device_status !== undefined ? (userData.device_controls.device_status ? 'lock' : 'unlock') : 'none')
                                            }`} onClick={handleClickLockDevice}>{finance?.device_controls?.device_status !== undefined ? (finance.device_controls.device_status ? 'Lock' : 'Unlock') : (userData?.device_controls?.device_status !== undefined ? (userData.device_controls.device_status ? 'Lock' : 'Unlock') : '-')}</button>
                                        <button className="action-btn uninstall" onClick={handleUnInstallPolicy}>Uninstall</button>
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Status :</div> <div className="info-text">{finance?.status || userData?.status || '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">OS :</div> <div className="info-text"> {finance?.os || userData?.os || '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Screen Lock Pin :</div> <div className="info-text"> {finance?.device_details?.customer_device_pin ?? userData?.device_details?.customer_device_pin ?? '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">App Status :</div> <div className="info-text">{finance?.device_controls?.app_lock_status !== undefined ? finance.device_controls.app_lock_status.toString() : userData?.device_controls?.app_lock_status !== undefined ? userData.device_controls.app_lock_status.toString() : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Call Status :</div> <div className="info-text">-</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Customer Location :</div>
                                    {/* <div className="info-text">{finance ? finance.device_controls?.location : userData ? userData.device_controls?.location : ""}</div> */}
                                    <div className="info-text">{finance?.device_controls?.location ?? userData?.device_controls?.location ?? '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">SIM Info :</div> <div className="info-text"> {finance?.device_controls?.sim_info !== undefined ? finance.device_controls.sim_info || '-' : userData?.device_controls?.sim_info !== undefined ? userData.device_controls.sim_info || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Brand :</div> <div className="info-text">{finance?.device_details?.Brand !== undefined ? finance.device_details.Brand : userData?.device_details?.Brand !== undefined ? userData.device_details.Brand : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Manufacture :</div> <div className="info-text">{finance?.device_details?.manufacturer !== undefined ? finance.device_details.manufacturer : userData?.device_details?.manufacturer !== undefined ? userData.device_details.manufacturer : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Model :</div> <div className="info-text">  {finance?.device_details?.model != null ? finance.device_details.model : userData?.device_details?.model != null ? userData.device_details.model : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">IMEI 1 :</div> <div className="info-text"> {finance?.device_details?.imei_slot_1 != null ? finance.device_details.imei_slot_1 : userData?.device_details?.imei_slot_1 != null ? userData.device_details.imei_slot_1 : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">IMEI 2 :</div> <div className="info-text">{finance?.device_details?.imei_slot_2 != null ? finance.device_details.imei_slot_2 : userData?.device_details?.imei_slot_2 != null ? userData.device_details.imei_slot_2 : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Serial Number :</div>
                                    <div className="info-text">{finance?.device_details?.serial_number != null ? finance.device_details.serial_number : userData?.device_details?.serial_number != null ? userData.device_details.serial_number : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Device Install Date :</div>
                                    <div className="info-text">{finance?.created_at ? dayjs(finance.created_at).format('DD-MM-YYYY') : userData?.created_at ? dayjs(userData.created_at).format('DD-MM-YYYY') : '-'}</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div className="device-details-container">
                            <div className="device-info1">
                                <div className="device-image">
                                    <h2>Retailer Details</h2>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">User name :</div> <div className="info-text"> {finance?.retailer?.name !== undefined ? finance.retailer.name || '-' : userData?.retailer?.name !== undefined ? userData.retailer.name || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Company name :</div> <div className="info-text">{finance?.retailer?.company_name !== undefined ? finance.retailer.company_name || '-' : userData?.retailer?.company_name !== undefined ? userData.retailer.company_name || '-' : '-'}</div>
                                </div>
                            </div>
                        </div>
                        <div className="device-details-container mt-4">
                            <div className="device-info1">
                                <div className="device-image">
                                    <h2>Customer Details</h2>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Code No. :</div> <div className="info-text">{finance?.code_no !== undefined ? finance.code_no || '-' : userData?.code_no !== undefined ? userData.code_no || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Name :</div> <div className="info-text"> {finance?.full_name !== undefined ? finance.full_name || '-' : userData?.full_name !== undefined ? userData.full_name || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Email :</div> <div className="info-text">{finance?.email !== undefined ? finance.email || '-' : userData?.email !== undefined ? userData.email || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Mobile :</div> <div className="info-text"> {finance?.mobile_number !== undefined ? finance.mobile_number || '-' : userData?.mobile_number !== undefined ? userData.mobile_number || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">IMEI 1 :</div> <div className="info-text">{finance?.imei_1 !== undefined ? finance.imei_1 || '-' : userData?.imei_1 !== undefined ? userData.imei_1 || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">IMEI 2 :</div> <div className="info-text">{finance?.imei_2 !== undefined ? finance.imei_2 || '-' : userData?.imei_2 !== undefined ? userData.imei_2 || '-' : '-'}</div>
                                </div>
                                <div className="info-row">
                                    <div className="info-title">Customer Reg.Date :</div> <div className="info-text">{finance?.created_at ? dayjs(finance.created_at).format('DD-MM-YYYY') : userData?.created_at ? dayjs(userData.created_at).format('DD-MM-YYYY') : '-'}</div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}

export default FinanceLocker;