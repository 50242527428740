//working 
import React, { useState, useRef, useEffect } from "react"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MdRefresh } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    Paper,
} from '@mui/material';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import TextField from '@mui/material/TextField';
import { images } from "../../config";
import dayjs from 'dayjs';
import './RetailersList.css';
import { getReatiler, getCountry, getState, getCity, getParentRole, getParentUser, getRetailerExcelExcel, retailerStatus } from "./service";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const columns = [
    { id: 'sr', label: 'Sr' },
    { id: 'name', label: 'Name' },
    { id: 'company_name', label: 'Company Name' },
    { id: 'mobile_number', label: 'Mobile' },
    { id: 'created_by__name', label: 'Parent Name' },
    { id: 'name', label: 'Owner Name' },
    { id: 'credit', label: 'Credit' },
    { id: 'debit', label: 'Debit' },
    { id: 'wallet_point', label: 'Android Balance' },
    { id: 'status', label: 'Status' },
    { id: 'Action', label: 'Action' },
];

const RetailersList = () => {

    const [country, setCountry] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [state, setState] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [city, setCity] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [parentRole, setParentRole] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [parentUserName, setParentUserName] = useState([]);
    const [status, setStatus] = useState('');
    const [selectedUserId, setSelectedUserId] = useState();
    const [companyName, setcompanyName] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sr');
    const [action, setAction] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [userName, setUserName] = useState({ name: "" });
    const [selectedParentName, setSelectedParentName] = useState([]);
    const [selectedFormDate, setSelectedFormDate] = useState();
    const [selectedToDate, setSelectedToDate] = useState();
    const [retailerData, setRetailerData] = useState([]);
    const [dropdownValues, setDropdownValues] = useState({});
    const [sortedDataList, setSortedDataList] = useState([]);
    const [count, setCount] = useState(0);
    const [refresh, setRefresh] = useState(null);
    const isInitialRender = useRef(true);


    const navigate = useNavigate();

    const notify = (message) => {
        toast.success(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const errorNotify = (message) => {
        toast.error(message, {
            theme: "colored",
            position: "top-right",
            className: 'custom-toast',
        });
    }

    const handleClick = () => {
        navigate('/addRetailer')
    }

    const handleClickUserView = (rowId) => {
        navigate(`/retailerView/${rowId}/false`);
    }

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    }

    const handleChangeCompany = (event) => {
        setcompanyName(event.target.value);
    }

    // const handleChangeAction = (event) => {
    //     setAction(event.target.value);
    // };


    const handleChangeAction = async (event, rowId) => {
        const selectedAction = event.target.value;
        setDropdownValues(prevValues => ({
            ...prevValues,
            [rowId]: selectedAction,
        }));

        if (selectedAction === "view") {
            navigate(`/retailerView/${rowId}/false`);
        }
        if (selectedAction === "edit") {
            navigate(`/retailerView/${rowId}/true`);
        }

        if (selectedAction === "Blocked" || selectedAction === "Unblocked") {
            const requestData = {
                status: selectedAction,
                retailer_id: rowId
            };

            console.log("requestData", requestData);

            try {
                const response = await retailerStatus(requestData);
                handleSubmit();
                console.log(`Retailer ${selectedAction}ed successfully`, response.data);
                // setDropdownValues(prevValues => ({
                //     ...prevValues,
                //     [rowId]: newStatus
                // }));

            } catch (error) {
                console.error(`Error ${selectedAction}ing distributor`, error);
            }
        }

    };


    const handleSort = (property, orderType) => {
        setOrder(order);
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchParentRole();
        fetchCountry();
        handleSubmit();
        console.log("handlesubmit");
    }, []);

    useEffect(() => {

        // Skip the effect logic on the initial render
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        handleSubmit();
        console.log("handlesubmit:changepage", page, rowsPerPage);
    }, [page, rowsPerPage]);


    const handleChangeName = (e) => {
        setUserName({
            ...userName,
            [e.target.name]: e.target.value,
        });
    }

    const handleFormdateChange = (newDate) => {
        setSelectedFormDate(newDate);
        const formDate = dayjs(newDate).format('YYYY-MM-DD');
        console.log("Formatted Date:", formDate);
    };

    const handleTodateChange = (newDate) => {
        setSelectedToDate(newDate);
        const toDate = dayjs(newDate).format('YYYY-MM-DD');
        console.log("FormattedTo Date:", toDate);
    };

    //Get Country list
    const fetchCountry = async () => {
        try {
            const response = await getCountry()
            if (response.status === 200 || response.status === 201) {
                setCountry(response.data.payload)
                console.log("setCountry", response);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // Get state list
    const handleChangeCountry = async (event) => {
        const selectedCountryValue = event.target.value;
        setSelectedCountry(selectedCountryValue);
        console.log("SelectedCountry", selectedCountryValue);
        const data = {
            name: selectedCountryValue,
        };
        console.log("data123country", data);
        try {
            const response = await getState(data);
            if (response.status === 200 || response.status === 201) {
                console.log("stateResponse", response.data.states);
                setState(response.data.states);

            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    // Get city list
    const handleChangeState = async (event) => {
        const selectedStateValue = event.target.value;
        setSelectedState(selectedStateValue);
        console.log("SelectedState", selectedStateValue);
        const data = {
            state_name: selectedStateValue,
        }
        console.log("dataState", data);
        try {
            const response = await getCity(data);
            if (response.status === 200 || response.status === 201) {
                console.log("cityResponse", response);
                setCity(response.data.cities)
            }
        } catch (error) {
            console.log("Error sending form data:", error);
        }
    };

    const handleChangeCity = (event) => {
        const selectedCityValue = event.target.value;
        setSelectedCity(selectedCityValue);
    }

    //Get Parent role
    const fetchParentRole = async () => {
        try {
            const response = await getParentRole()
            if (response.status === 200 || response.status === 201) {
                setParentRole(response.data.payload)
                console.log("parentRole", response);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    //Get Parent user
    const handleChangeUserRole = async (event) => {
        const role_name = event.target.value;
        setSelectedRole(role_name);
        const data = {
            user_role: role_name,
        };
        console.log("data123", data);
        try {
            const response = await getParentUser(data);
            if (response.status === 200 || response.status === 201) {
                console.log("parentUserResponse", response);
                const users = response.data.payload.map(user => ({
                    name: user.name,
                    userId: role_name === "Superadmin" ? user.id : user.user
                }));
                console.log("mapedusers", users);
                setParentUserName(users);
                console.log("parentUserName State Updated:", parentUserName);
            }
        } catch (error) {
            console.error("Error sending form data:", error);
        }
    };

    const handleSubmit = async () => {
        const selectedUser = parentUserName.find(user => user.userId === selectedUserId);
        const createdByName = selectedUser ? selectedUser.name : '';
        console.log("createdName", createdByName);
        const data = {
            name: userName,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
            status: status,
            created_by__name: createdByName,
            parent_role: selectedRole,
        }
        console.log("retailer", data);
        const fromDate = selectedFormDate ? dayjs(selectedFormDate).format('YYYY-MM-DD') : "";
        const toDate = selectedToDate ? dayjs(selectedToDate).format('YYYY-MM-DD') : "";
        try {
            const pageSize = rowsPerPage || 10;
            const response = await getReatiler(data, userName, createdByName, selectedRole, selectedCountry, selectedState, selectedCity, status, fromDate, toDate, page, pageSize);
            if (response.status === 200 || response.status === 201) {
                const results = response.data.results;
                console.log("reatiler123444", response.data.results);
                setSelectedFormDate(null);
                setSelectedToDate(null);
                if (results.length === 0) {
                    errorNotify("Retailer not found");
                    setCount(0);
                    setRetailerData([]);
                } else {
                    console.log("count", response.data.count)
                    setRetailerData(response.data.results);
                    setCount(response.data.count);
                    console.log("reaponseretailer", response.data.results)
                    notify(response.data.message);
                }
            }
        } catch (error) {
            console.error("Error sending form data:", error);
            errorNotify(error.response?.data?.message);
        }
    }

    const handleRefresh = () => {
        setUserName({ ...userName, name: '' });
        setSelectedCountry("");
        setSelectedState("");
        setSelectedCity("");
        setSelectedRole("");
        setParentUserName([]);
        setStatus("");
        setRefresh(refresh === 0 ? 1 : 0);
    };

    useEffect(() => {
        if (refresh !== null) {
            handleSubmit();
            console.log("handlesubmit:refresh");
        }

    }, [refresh]);


    const handleExportExcel = async () => {
        const selectedUser = parentUserName.find(user => user.userId === selectedUserId);
        const createdByName = selectedUser ? selectedUser.name : '';
        const data = {
            name: userName,
            country: selectedCountry,
            state: selectedState,
            city: selectedCity,
            status: status,
            created_by__name: createdByName,
            parent_role: selectedRole,
        }
        console.log("retailerExcel", data);
        const fromDate = selectedFormDate ? dayjs(selectedFormDate).format('YYYY-MM-DD') : "";
        const toDate = selectedToDate ? dayjs(selectedToDate).format('YYYY-MM-DD') : "";
        try {
            const response = await getRetailerExcelExcel(userName, createdByName, selectedRole, selectedCountry, selectedState, selectedCity, status, fromDate, toDate,);
            console.log("response", response);
        }
        catch (error) {
            console.error("Error sending form data:", error);
        }
    }

    const sortData = () => {
        setSortedDataList([...retailerData].sort((a, b) => {
            let valueA = a[orderBy];
            let valueB = b[orderBy];
            // Handle undefined values
            // Handle undefined or null values
            if (valueA === undefined || valueA === null) valueA = '';
            if (valueB === undefined || valueB === null) valueB = '';


            // Check if both values are numeric
            if (!isNaN(valueA) && !isNaN(valueB)) {
                valueA = parseFloat(valueA);
                valueB = parseFloat(valueB);
            } else {
                // Ensure strings are compared case-insensitively
                valueA = valueA.toString().toLowerCase();
                valueB = valueB.toString().toLowerCase();
            }

            if (order === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        }));
    };

    useEffect(() => {
        sortData();
    }, [order, orderBy, retailerData]);


    return (
        <>
            <ToastContainer />
            <Container maxWidth="xxl" className="pt-4">
                <div className="header-text-wrap">
                    <div className="total-count">
                        <h2 className="header-text">Retailer List</h2>
                        <p>Total records - {count}</p>
                    </div>
                    <div>
                        <button onClick={handleClick} className="add-distributor-btn">
                            Add Retailer
                        </button>
                    </div>
                </div>
            </Container>
            <Container maxWidth="xxl" className="pt-4">
                <div className="search-data-bg">
                    <div className="retailer-list-form">
                        <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="retailer-input-wrap">
                                    <div>
                                        <label className="retailer-label">Search By Name or Company Name</label>
                                    </div>
                                    <div className="input-wrap">
                                        <input name="name" placeholder="" value={userName.name} onChange={handleChangeName} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">Country</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Search for country</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={selectedCountry}
                                            onChange={handleChangeCountry}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {country.map((country) => (
                                                <MenuItem key={country.id} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} className="pt-4">
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">State</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Search for state</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={selectedState}
                                            onChange={handleChangeState}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {state.map((state, index) => (
                                                <MenuItem key={index} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} className="pt-4">
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">City</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Search for city</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={selectedCity}
                                            onChange={handleChangeCity}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {Array.isArray(city) && city.length > 0 ? (
                                                city.map((city, index) => (
                                                    <MenuItem key={index} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>
                                                    <em>No cities available</em>
                                                </MenuItem>
                                            )}
                                        </Select>

                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2} className="pt-4">
                                <div className="distributer-list-search-box">
                                    <label className="distributer-list-label">Status</label>
                                    <FormControl variant="filled" className="distributer-details-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label" className="distributer-dropdown-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            className="distributer-dropdown"
                                            value={status}
                                            onChange={handleChangeStatus}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Unblocked">Unblock</MenuItem>
                                            <MenuItem value="Blocked">Block</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={{ xs: 2, md: 3 }} className="pt-4">
                            <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4">
                                <div className="retailer-input-wrap">
                                    {/* <div>
                                    <label className="retailer-label">Parent Role</label>
                                </div>
                                <div className="input-wrap">
                                    <input />
                                </div> */}
                                    <label className="reatiler-label mb-1">Parent Role</label>
                                    <FormControl variant="filled" className="retailer-country-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={selectedRole}
                                            onChange={handleChangeUserRole}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {parentRole.map((role) => (
                                                <MenuItem key={role.id} value={role.role_name}>
                                                    {role.role_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4">
                                <div className="retailer-input-wrap">
                                    {/* <div>
                                    <label className="retailer-label">Parent User</label>
                                </div>
                                <div className="input-wrap">
                                    <input />
                                </div> */}
                                    <label className="reatiler-label mb-1">Parent User</label>
                                    <FormControl variant="filled" className="retailer-country-dropdown">
                                        <InputLabel id="demo-simple-select-filled-label">Select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={selectedUserId}
                                            onChange={(event) => setSelectedUserId(event.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {parentUserName.map((user, index) => (
                                                <MenuItem key={index} value={user.userId}>
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4 custom-label">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoItem label="From Date">
                                        <DesktopDatePicker
                                            value={selectedFormDate}
                                            onChange={handleFormdateChange}
                                            className="custom-input"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </DemoItem>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} className="pt-4 custom-label">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoItem label="To Date">
                                        <DesktopDatePicker
                                            value={selectedToDate}
                                            onChange={handleTodateChange}
                                            className="custom-input"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </DemoItem>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <div className="retailer-btns-wrap">
                            <div>
                                <MdRefresh className="refresh-icon" onClick={handleRefresh} />
                            </div>
                            <div>
                                <button className="cancel-btn" onClick={handleExportExcel}>Export</button>
                            </div>
                            <div>
                                <button className="submit-btn" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Container maxWidth="xxl" className="pt-4 pb-5">
                <TableContainer component={Paper}>
                    <Table className="retailer-list-table-wrap">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <>
                                        <TableCell key={column.id} className="retailer-list-table-header-bg"
                                            sx={{
                                                border: 'none',
                                                '&:last-child': { border: 0 }
                                            }}
                                        >
                                            <div className="header-text-wrapper">
                                                <div className="header-text">
                                                    {column.label}
                                                </div>

                                                <div>
                                                    <div>
                                                        <IoIosArrowUp
                                                            onClick={() => handleSort(column.id, 'desc')} className="up-icon"
                                                        >
                                                        </IoIosArrowUp>
                                                    </div>

                                                    <div>
                                                        <IoIosArrowDown
                                                            onClick={() => handleSort(column.id, 'asc')} className="down-icon"
                                                        >
                                                        </IoIosArrowDown>
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody className="retailer-list-table">
                            {sortedDataList.map((row, index) => (
                                <TableRow ey={row.id || index}>
                                    <TableCell sx={{ padding: 0 }}>{page * rowsPerPage + index + 1}</TableCell>
                                    <TableCell sx={{ padding: 0 }}>{row.name}</TableCell>
                                    <TableCell onClick={() => handleClickUserView(row.id)}>{row.company_name}</TableCell>
                                    <TableCell>{row.mobile_number}</TableCell>
                                    <TableCell>{row.created_by}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.credit}</TableCell>
                                    <TableCell>{row.debit}</TableCell>
                                    <TableCell>
                                        <button className="wallet-point">
                                            <img
                                                src={images.keyImage}
                                                alt="image"
                                            /> {row.wallet_point}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        <button className={row.status === "Unblocked" ? "status-unblock" : row.status === "Blocked" ? "status-block" : row.status === null ? "status-null" : ""}>
                                            {row.status ? row.status : "-"}
                                        </button>
                                    </TableCell>
                                    <TableCell>
                                        <FormControl sx={{ m: 1 }} className="distributer-list-action-dropdown">
                                            <Select
                                                value={dropdownValues[row.id] || "action"}
                                                onChange={(e) => handleChangeAction(e, row.id)}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                className="distributer-list-action-dropdown"
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        border: 0,
                                                    },
                                                    fontFamily: 'Satoshi-Regular',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.5px'
                                                }}
                                            >
                                                <MenuItem value="action">
                                                    Action
                                                </MenuItem>
                                                <MenuItem value="view">View</MenuItem>
                                                <MenuItem value="edit">Edit</MenuItem>
                                                <MenuItem value="Blocked">Block</MenuItem>
                                                <MenuItem value="Unblocked">Unblock</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

            </Container>
        </>
    )
}

export default RetailersList;

